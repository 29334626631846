import { Box, Flex } from '@storyofams/react-ui';
import { format } from 'date-fns';
import SbEditable from 'storyblok-react';
import { Heading, Text, Divider, DynamicSections } from '~/components';

export const UpdateSection = ({ content, id }) => {
  const date = Date.parse(content.date);

  return (
    <SbEditable content={content || {}} key={content?._uid}>
      <Box
        mx={[0, 0, 'auto', 5]}
        id={id}
        maxWidth="764px"
        css={`
          scroll-margin-top: 100px;
        `}
      >
        <Flex justifyContent="space-between">
          <Heading variant="h3" as="h2" color="primary">
            {content?.title}
          </Heading>
          {!isNaN(date) && (
            <Text fontSize="15px" color="grey200">
              {format(Date.parse(content.date), 'PPP')}
            </Text>
          )}
        </Flex>
        <Divider mt="24px !important" mb="32px !important" />
      </Box>
      <DynamicSections sections={content?.body} />
    </SbEditable>
  );
};
