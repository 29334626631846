import { Box, Flex, SystemProps } from '@storyofams/react-ui';
import { getLinkProps } from '~lib';
import { Button } from '../Button';
import { Image } from '../DynamicSections/components/Image';
import { Heading } from '../Heading';
import { Text } from '../Text';

interface CardProps extends SystemProps {
  bg?: any;
  link?: any;
  title: string;
  description: string;
  button: string;
}

export const Card = ({ bg, link, title, description, button }: CardProps) => {
  return (
    <Button variant="unstyled" to={getLinkProps(link)}>
      <Box
        height="320px"
        width={['100%', '370px']}
        maxWidth="370px"
        border="1px"
        borderColor="grey100"
        borderRadius="md"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        {bg?.filename ? (
          <Image image={bg} borderRadius="none" width="100%" height="122px" />
        ) : (
          <Box backgroundColor="primary" width="100%" height="122px" />
        )}
        <Flex
          flexDirection="column"
          px={3}
          pt={2}
          pb={3}
          justifyContent="space-between"
          flex={1}
        >
          <Box>
            <Heading variant="h4" as="h3">
              {title}
            </Heading>
            <Text variant="m" as="p" mt={1}>
              {description}
            </Text>
          </Box>
          <Button variant="primary" mt={3} to={getLinkProps(link)}>
            {button}
          </Button>
        </Flex>
      </Box>
    </Button>
  );
};
