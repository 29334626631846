import dynamic from 'next/dynamic';
import { Container } from '~/components';
import type { DynamicSection as DynamicSectionType } from './sections/DynamicSection';

const DynamicSection = dynamic(() =>
  import('./sections/DynamicSection').then((mod) => mod.DynamicSection),
) as typeof DynamicSectionType;

const Sections = {
  dynamic_section: DynamicSection,
};

export const DynamicSections = ({ sections, ...props }) => {
  return sections?.length
    ? sections?.map((section) => {
        if (!!section && typeof Sections[section?.component] !== 'undefined') {
          const Section = Sections[section?.component];

          return (
            <Container mb={8} space={3} {...props}>
              <Section key={section?._uid} {...section} />
            </Container>
          );
        }

        return null;
      })
    : null;
};
