import { Box, css } from '@storyofams/react-ui';
import {
  Image as ToolkitImage,
  getImageProps,
} from '@storyofams/storyblok-toolkit';

export const Image = ({ image, ...props }) => {
  const width = getImageProps(image?.filename)?.width;

  return (
    <Box
      position="relative"
      overflow="hidden"
      height="100%"
      width="100%"
      maxWidth={`${Math.min(width, 764)}px`}
      css={css({
        '> div': {
          width: '100%',
        },
        img: {
          width: '100%',
          maxWidth: '100%',
        },
      })}
      {...props}
    >
      <ToolkitImage
        alt={image?.alt || ''}
        src={image?.filename}
        fluid={1200}
        fit="contain"
        showPlaceholder={false}
      />
    </Box>
  );
};
