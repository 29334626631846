import { Box, Stack } from '@storyofams/react-ui';
import { withStory, WithStoryProps } from '@storyofams/storyblok-toolkit';

import { GetStaticProps, NextPage } from 'next';

import SbEditable from 'storyblok-react';
import { Container, Heading, Seo, Text, Card } from '~components';
import { enhancedStaticProps, withLayout } from '~lib';
import { UpdateSection } from '~components/common/UpdateSection';
import { ProductupdateItems } from '~lib/storyblok/sdk';

interface HomePageProps extends WithStoryProps {
  productUpdates: ProductupdateItems;
}

const Home: NextPage<HomePageProps> = ({ story, productUpdates }) => {
  return (
    <>
      <Seo story={story} />
      <SbEditable content={story?.content}>
        <Container>
          <Box>
            <Heading variant="h2" as="h1">
              {story?.content?.title}
            </Heading>
            <Text variant="m" as="p" mt={2} mb={4} color="grey400">
              {story?.content?.description}
            </Text>
            <Box mt={4}>
              <Stack mt={4} space={3} flexDirection={['column', 'row']}>
                {story?.content?.cards?.map((card) => (
                  <Card {...card} flex={1} />
                ))}
              </Stack>
            </Box>
          </Box>
        </Container>
        <Container mt={8}>
          <Heading variant="h3" as="h2" mb={6}>
            Product updates
          </Heading>
        </Container>
      </SbEditable>

      {productUpdates?.items?.map((update) => (
        <SbEditable content={update?.content as any}>
          <UpdateSection content={update?.content} id={update?.uuid} />
        </SbEditable>
      ))}
    </>
  );
};

export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps<HomePageProps> =
  enhancedStaticProps(async ({ sdk }) => {
    let story = null;
    let productUpdates = null;
    let notFound = false;

    try {
      story = (
        await sdk.home({
          slug: `home`,
        })
      ).HomeItem;
    } catch (e) {
      notFound = true;
    }

    try {
      productUpdates = (await sdk.productUpdates({ page: 1, perPage: 2 }))
        .ProductupdateItems;
    } catch (e) {
      console.log(e);
    }

    return {
      props: {
        story,
        productUpdates,
      },
      notFound: notFound || !story,
      revalidate: 60,
    };
  });
