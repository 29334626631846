import { NextSeo, NextSeoProps } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { seo } from '~config';
import { getDescription } from '~lib';

interface SeoProps extends NextSeoProps {
  story?: any;
  image?: string;
  ogType?: 'website' | 'article';
  noIndex?: boolean;
}

const messages = {
  title: 'Lantern',
};

export const Seo = ({
  story: storyProp,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  ogType,
  noIndex,
  ...props
}: SeoProps) => {
  const router = useRouter();
  const { defaultLocale, pathname, locale, locales } = router;

  let title = titleProp;
  let description = descriptionProp;
  let image = { url: imageProp, width: 1200, height: 627 };

  const story = storyProp?.content || storyProp;

  if (story) {
    if (!title) {
      title = story.seo?.title || story.title;
    }
    if (!description) {
      description = getDescription(story);
    }
    if (!imageProp) {
      image = {
        url: story?.seo?.og_image || story?.image?.filename,
        width: 1200,
        height: 627,
      };
    }
  }

  const isHome = locales
    .map((l) => `/${defaultLocale !== l ? `${l}/` : ''}`)
    .includes(pathname);

  if (title && isHome) {
    title = `Lantern - ${title}`;
  } else {
    title = title ?? messages.title;
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        noindex={story?.noIndex === true || noIndex}
        openGraph={{
          images: [image],
          type: ogType || 'website',
          locale,
          site_name: 'Lantern',
        }}
        {...props}
        titleTemplate={
          title?.includes('Lantern') ? '%s' : seo.titleTemplate
        }
      />
      <Head>
        {locales
          ?.filter((l) => l !== defaultLocale)
          ?.map((content) => (
            <meta
              key={content}
              property="og:locale:alternate"
              content={content}
            />
          ))}
      </Head>
    </>
  );
};
